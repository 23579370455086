import { Box } from '../Box';
import { styled } from '../styled';

/**
 * Pressed component.
 */
export const Pressed = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-flex',

  ':hover, :hover > *': {
    color: theme.palette.hover,
  },
}));
