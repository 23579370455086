import { GlobalClasses } from 'helpers';
import { GlobalStyles } from 'libs/ui/styled';

export const GlobalStyled = (
  <GlobalStyles
    styles={(theme) => ({
      [`.${GlobalClasses.hovered}:hover, .${GlobalClasses.hovered}:hover > *`]:
        {
          color: theme.palette.hover,
        },
    })}
  />
);
