import { useTranslation } from 'libs/i18n';
import { Button, Typography, Stack, ListTypography } from 'libs/ui';

import { BodyStyled, LayoutStyled } from './OnboardingInfoView.styles';

interface Props {
  texts: string[];
  onOnboadring?: () => void;
}

export const OnboardingInfoView = ({ texts, onOnboadring }: Props) => {
  const { t } = useTranslation();

  return (
    <LayoutStyled>
      <BodyStyled className="registration-onboarding">
        <Stack className="registration-onboarding-content" spacing={32}>
          <Typography variant="header" variantMobile="subtitle">
            {t('onboarding-info.title')}
          </Typography>

          <Typography
            variant="bodyBold"
            variantMobile="captionBold"
            whiteSpace="pre-wrap"
          >
            {t('onboarding-info.subtitle')}
          </Typography>

          <ListTypography
            listType="ul"
            spacing={16}
            texts={texts}
            variantMobile="caption"
          />
          <Stack className="registration-onboarding-button">
            <Button
              label={t('onboarding-info.button')}
              onClick={onOnboadring}
            />
          </Stack>
        </Stack>
      </BodyStyled>
    </LayoutStyled>
  );
};
